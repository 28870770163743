import {
  SET_AUTH,
  SET_VERIFY_LINK,
  SET_VERIFY_LINK_SUCCESS,
} from "./actionTypes";

export const handleSetAuth = (data) => ({
  type: SET_AUTH,
  payload: data,
});

export const setVerifyLink = (data) => ({
  type: SET_VERIFY_LINK,
  payload: data,
});

export const setVerifyLinkSuccess = (data) => ({
  type: SET_VERIFY_LINK_SUCCESS,
  payload: data,
});
