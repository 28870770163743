import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import CustomTextField from "../../../components/common/CustomTextField";
import { Card } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { routesConstants } from "../../../routes/routesConstants";
import { toast } from "react-toastify";
import ExitConfirmationModal from "../../../components/common/ExitConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { axiosMilliardAdmin } from "services/api";
import { ADD_UPDATE_PRODUCT } from "constants/url";
import { fetchProductDetails, setProductDetailSuccess } from "../store/actions";
import Select from "react-select";
import { productUnits } from "constants";
import { weightUnits } from "constants";

const initialValues = {
  product_id: 0,
  sku: "",
  is_from_admin: 0,
  product_variants: [
    {
      product_variant_id: 0,
      variant_name: "",
      longest_side: 0,
      longest_side_unit: 1,
      median_side: 0,
      median_side_unit: 1,
      shortest_side: 0,
      shortest_side_unit: 1,
      weight: 0,
      weight_unit: 1,
      units_per_case: 0,
      length_girth: "",
      dimensional_weigth: "",
    },
  ],
};

const validationSchema = Yup.object().shape({
  sku: Yup.string().required("Please enter SKU."),
  product_variants: Yup.array().of(
    Yup.object().shape({
      variant_name: Yup.string().trim().required("Please enter variant name."),
      longest_side: Yup.number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please enter Longest Side.")
        .test("greater-than-zero", "Greater than 0", (value) => {
          return value > 0;
        }),
      median_side: Yup.number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please enter median side.")
        .test("greater-than-zero", "Greater than 0", (value) => {
          return value > 0;
        }),
      shortest_side: Yup.number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please enter shortest side.")
        .test("greater-than-zero", "Greater than 0", (value) => {
          return value > 0;
        }),
      weight: Yup.number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please enter weight.")
        .test("greater-than-zero", "Greater than 0", (value) => {
          return value > 0;
        }),
      units_per_case: Yup.number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("Please enter units per case.")
        .test("greater-than-zero", "Greater than 0", (value) => {
          return value > 0;
        }),
    })
  ),
});

const AddEditProduct = () => {
  const [isExitModel, setIsExitModel] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product_id } = useParams();
  const [params] = useSearchParams();
  const query = useLocation();
  const editMode = params.get("mode") === "edit";
  const dimDivisor = localStorage.getItem("dim_divisor");

  const { productDetail } = useSelector((state) => state.productReducer);

  const isViewMode = useMemo(() => {
    return query?.pathname.split("/").includes("view") ? true : false;
  }, [query]);

  const customStyles = {
    container: (base) => ({
      ...base,
      border: "1px solid #e8e8e8 !important",
      width: "30% !important",
    }),
    control: (base) => ({
      ...base,
      border: "none !important",
      outline: "none !important",
      boxShadow: "none !important",
      minHeight: "42px !important",
    }),
    input: (base) => ({
      ...base,
      height: "30px !important",
      border: "none !important",
      opacity: 0,
    }),
  };

  const onSubmit = async (values) => {
    try {
      const payload = {
        product_id: product_id ? product_id : 0,
        sku: values.sku,
        is_from_admin: 0,
        product_variants: values.product_variants?.map((i) => ({
          product_variant_id: i.product_variant_id,
          variant_name: i.variant_name,
          longest_side: i.longest_side,
          median_side: i.median_side,
          shortest_side: i.shortest_side,
          weight: i.weight,
          units_per_case: i.units_per_case,
          length_girth: i.length_girth,
          dimensional_weigth: i.dimensional_weigth,
        })),
      };
      const res = await axiosMilliardAdmin.post(ADD_UPDATE_PRODUCT, payload);
      if (res) {
        dispatch(setProductDetailSuccess(null));

        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (editMode) {
          navigate(`${routesConstants.VIEW_PRODUCT}/${product_id}`);
        } else {
          navigate(`/${routesConstants.PRODUCTS}`);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const {
    values,
    errors,
    handleSubmit,
    touched,
    validateField,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (!product_id) {
      resetForm({
        values: {
          product_id: 0,
          sku: "",
          is_from_admin: 0,
          product_variants: [
            {
              product_variant_id: 0,
              variant_name: "",
              longest_side: 0,
              longest_side_unit: 1,
              median_side: 0,
              median_side_unit: 1,
              shortest_side: 0,
              shortest_side_unit: 1,
              weight: 0,
              weight_unit: 1,
              units_per_case: 0,
              length_girth: "",
              dimensional_weigth: "",
            },
          ],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  useEffect(() => {
    if (!!productDetail && product_id) {
      resetForm({
        values: {
          product_id: productDetail.product_id,
          sku: productDetail.sku,
          is_from_admin: 0,
          product_variants:
            productDetail.product_variants?.length === 0
              ? [
                  {
                    product_variant_id: 0,
                    variant_name: "",
                    longest_side: 0,
                    longest_side_unit: 1,
                    median_side: 0,
                    median_side_unit: 1,
                    shortest_side: 0,
                    shortest_side_unit: 1,
                    weight: 0,
                    weight_unit: 1,
                    units_per_case: 0,
                    length_girth: "",
                    dimensional_weigth: "",
                  },
                ]
              : productDetail.product_variants.map((i) => ({
                  product_variant_id: i.product_variant_id,
                  variant_name: i.product_variant_name,
                  longest_side: i.longest_side,
                  longest_side_unit: 1,
                  median_side: i.median_side,
                  median_side_unit: 1,
                  shortest_side: i.shortest_side,
                  shortest_side_unit: 1,
                  weight: i.weight,
                  weight_unit: 1,
                  units_per_case: i.units_per_case,
                  length_girth: i.length_girth,
                  dimensional_weigth: i.dimensional_weigth,
                })),
        },
      });
    }
  }, [resetForm, dispatch, product_id, productDetail]);

  const handleAddVariant = (indexToInsertAt) => {
    const newArray = {
      product_variant_id: 0,
      variant_name: "",
      longest_side: 0,
      longest_side_unit: 1,
      median_side: 0,
      median_side_unit: 1,
      shortest_side: 0,
      shortest_side_unit: 1,
      weight: 0,
      weight_unit: 1,
      units_per_case: 0,
      length_girth: "",
      dimensional_weigth: "",
    };
    // Create a copy of the values.data array
    const newDataArray = [...values.product_variants];

    // Insert the 'newArray' element at the specified index
    newDataArray.splice(indexToInsertAt + 1, 0, newArray);

    // Update the 'data' field in the form values
    setFieldValue("product_variants", newDataArray);
  };

  const handleDeleteVariant = (item, index) => {
    let tempVariant = values.product_variants;
    if (product_id) {
      tempVariant?.splice(
        values?.product_variants.findIndex(
          (i) => i.product_variant_id === item.product_variant_id
        ),
        1
      );
    } else {
      tempVariant?.splice(index, 1);
    }
    setFieldValue({
      ...values,
      product_variants: tempVariant,
    });
  };

  const handleLengthGirth = (
    shortest_side,
    median_side,
    longest_side,
    index
  ) => {
    setFieldValue({
      ...values,
      product_variants: values.product_variants.map((i, x) => {
        if (index === x)
          i.length_girth =
            parseInt(shortest_side) * 2 +
            parseInt(median_side) * 2 +
            parseInt(longest_side);
        return i;
      }),
    });
  };

  const handleDimensionalWeight = (
    shortest_side,
    median_side,
    longest_side,
    index
  ) => {
    setFieldValue({
      ...values,
      product_variants: values.product_variants.map((i, x) => {
        if (index === x)
          i.dimensional_weigth = (
            (longest_side * median_side * shortest_side) /
            dimDivisor
          ).toFixed(2);
        return i;
      }),
    });
  };

  useEffect(() => {
    if (product_id) {
      dispatch(
        fetchProductDetails({
          product_id: product_id,
          is_from_admin: 0,
        })
      );
    }
  }, [dispatch, product_id]);

  const myErrors = errors?.product_variants;

  return (
    <>
      <div className="addPro">
        <Card className="p-4 mt-5 rounded-4 add_details_main add_product_card">
          <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom ">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-arrow-left"
                aria-hidden="true"
                onClick={() => {
                  setIsExitModel(true);
                  dispatch(setProductDetailSuccess(null));
                }}
              ></i>
              <h4>
                {product_id ? (isViewMode ? "View" : "Edit") : "Add"} Product
              </h4>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            {/* SKU */}
            <CustomTextField
              label="SKU"
              type="text"
              name="sku"
              value={values?.sku}
              onChange={(e) => {
                setFieldValue("sku", e.target.value).then(() =>
                  validateField("sku")
                );
              }}
              placeholder="SKU"
              error={!!errors?.sku && !!touched?.sku ? errors?.sku : ""}
              disabled={isViewMode}
            />
            <div className="d-flex pt-3 overflow-scroll add_product_plus_btn">
              {values?.product_variants?.length > 0 ? (
                values.product_variants?.map((i, index) => (
                  <>
                    <div className="add_product_details" key={index}>
                      <div className="actionButtons">
                        {values?.product_variants.length === 1 &&
                        !isViewMode ? (
                          <button
                            title="Add"
                            type="button"
                            className="addbutton btn p-3 text-black shadow-sm fw-bold button-addonleft"
                            onClick={() => handleAddVariant(1)}
                          >
                            Add Variant
                          </button>
                        ) : (
                          !isViewMode && (
                            <div>
                              <button
                                title="Add"
                                type="button"
                                className="btn btn-primary btn_add_row"
                                onClick={() => handleAddVariant(index)}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )
                        )}

                        {values?.product_variants?.length > 1 &&
                          !isViewMode && (
                            <div>
                              <button
                                title="Remove"
                                type="button"
                                onClick={() => {
                                  handleDeleteVariant(i, index);
                                }}
                                className="btn btn-outline-danger btn-sm btn_remove_row"
                              >
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="variantDiv">
                        <div className="mb-3">
                          <div>
                            <label>Variant Name</label>
                          </div>
                          <div className="variantName">
                            <input
                              type="text"
                              placeholder="Variant Name"
                              name={`product_variants.[${index}].variant_name`}
                              value={i["variant_name"]}
                              onChange={(e) => {
                                setFieldValue({
                                  ...values,
                                  product_variants:
                                    values.product_variants?.map((i, x) => {
                                      if (index === x)
                                        i.variant_name = e.target.value;
                                      return i;
                                    }),
                                }).then(() =>
                                  validateField(
                                    `product_variants.[${index}].variant_name`
                                  )
                                );
                              }}
                              disabled={isViewMode}
                            />
                          </div>
                          {myErrors
                            ? myErrors[index]?.variant_name && (
                                <div className="errorData">
                                  {myErrors[index]?.variant_name}
                                </div>
                              )
                            : ""}
                        </div>
                        <div>
                          <div>
                            <div>
                              <label>Longest Side</label>
                            </div>
                            <div className="sizeDiv">
                              <input
                                type="number"
                                placeholder="Longest Side"
                                name={`product_variants.[${index}].longest_side`}
                                value={i["longest_side"]}
                                disabled={isViewMode}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants?.map((i, x) => {
                                        if (index === x)
                                          i.longest_side = e.target.value;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_variants.[${index}].longest_side`
                                    )
                                  );
                                  handleLengthGirth(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                  handleDimensionalWeight(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                }}
                              />
                              <Select
                                options={productUnits}
                                placeholder="Unit"
                                name={`product_variants.[${index}].longest_side_unit`}
                                isDisabled={isViewMode}
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants?.map((i, x) => {
                                        if (index === x)
                                          i.longest_side_unit = e.value;
                                        return i;
                                      }),
                                  });
                                  if (e.value === 1) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants?.map((i, x) => {
                                          if (index === x)
                                            i.longest_side = (
                                              i.longest_side * 0.393701
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                  if (e.value === 0) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants?.map((i, x) => {
                                          if (index === x)
                                            i.longest_side = (
                                              i.longest_side * 2.54
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                }}
                                value={productUnits.filter(
                                  (x) => x.value === i["longest_side_unit"]
                                )}
                                styles={customStyles}
                              />
                            </div>
                            {myErrors
                              ? myErrors[index]?.longest_side && (
                                  <div className="errorData">
                                    {myErrors[index]?.longest_side}
                                  </div>
                                )
                              : ""}
                          </div>
                          <div>
                            <div>
                              <label>Median Side</label>
                            </div>
                            <div className="sizeDiv">
                              <input
                                type="number"
                                placeholder="Median Side"
                                name={`product_variants.[${index}].median_side`}
                                value={i["median_side"]}
                                disabled={isViewMode}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants?.map((i, x) => {
                                        if (index === x)
                                          i.median_side = e.target.value;

                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_variants.[${index}].median_side`
                                    )
                                  );
                                  handleLengthGirth(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                  handleDimensionalWeight(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                }}
                              />
                              <Select
                                options={productUnits}
                                placeholder="Unit"
                                isDisabled={isViewMode}
                                name={`product_variants.[${index}].median_side_unit`}
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants?.map((i, x) => {
                                        if (index === x)
                                          i.median_side_unit = e.value;
                                        return i;
                                      }),
                                  });
                                  if (e.value === 1) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants?.map((i, x) => {
                                          if (index === x)
                                            i.median_side = (
                                              i.median_side * 0.393701
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                  if (e.value === 0) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants.map((i, x) => {
                                          if (index === x)
                                            i.median_side = (
                                              i.median_side * 2.54
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                }}
                                value={productUnits.filter(
                                  (x) => x.value === i["median_side_unit"]
                                )}
                                styles={customStyles}
                              />
                            </div>
                            {myErrors
                              ? myErrors[index]?.median_side && (
                                  <div className="errorData">
                                    {myErrors[index]?.median_side}
                                  </div>
                                )
                              : ""}
                          </div>
                          <div>
                            <div>
                              <label>Shortest Side</label>
                            </div>
                            <div className="sizeDiv">
                              <input
                                type="number"
                                placeholder="Shortest Side"
                                name={`product_variants.[${index}].shortest_side`}
                                value={i["shortest_side"]}
                                disabled={isViewMode}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants.map((i, x) => {
                                        if (index === x)
                                          i.shortest_side = e.target.value;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_variants.[${index}].shortest_side`
                                    )
                                  );
                                  handleLengthGirth(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                  handleDimensionalWeight(
                                    i["shortest_side"],
                                    i["median_side"],
                                    i["longest_side"],
                                    index
                                  );
                                }}
                              />
                              <Select
                                options={productUnits}
                                placeholder="Unit"
                                isDisabled={isViewMode}
                                name={`product_variants.[${index}].shortest_side_unit`}
                                value={productUnits.filter(
                                  (x) => x.value === i["shortest_side_unit"]
                                )}
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants.map((i, x) => {
                                        if (index === x)
                                          i.shortest_side_unit = e.value;
                                        return i;
                                      }),
                                  });
                                  if (e.value === 1) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants.map((i, x) => {
                                          if (index === x)
                                            i.shortest_side = (
                                              i.shortest_side * 0.393701
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                  if (e.value === 0) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants.map((i, x) => {
                                          if (index === x)
                                            i.shortest_side = (
                                              i.shortest_side * 2.54
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                }}
                                styles={customStyles}
                              />
                            </div>
                            {myErrors
                              ? myErrors[index]?.shortest_side && (
                                  <div className="errorData">
                                    {myErrors[index]?.shortest_side}
                                  </div>
                                )
                              : ""}
                          </div>
                          <div>
                            <div>
                              <label>Weight</label>
                            </div>
                            <div className="sizeDiv">
                              <input
                                type="number"
                                placeholder="Weight"
                                name={`product_variants.[${index}].weight`}
                                value={i["weight"]}
                                disabled={isViewMode}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants.map((i, x) => {
                                        if (index === x)
                                          i.weight = e.target.value;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_variants.[${index}].weight`
                                    )
                                  );
                                }}
                              />
                              <Select
                                options={weightUnits}
                                placeholder="Unit"
                                isDisabled={isViewMode}
                                name={`product_variants.[${index}].weight_unit`}
                                value={weightUnits.filter(
                                  (x) => x.value === i["weight_unit"]
                                )}
                                styles={customStyles}
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants.map((i, x) => {
                                        if (index === x)
                                          i.weight_unit = e.value;
                                        return i;
                                      }),
                                  });
                                  if (e.value === 0) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants.map((i, x) => {
                                          if (index === x)
                                            i.weight = (
                                              i.weight * 0.45359237
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                  if (e.value === 1) {
                                    setFieldValue({
                                      ...values,
                                      product_variants:
                                        values.product_variants.map((i, x) => {
                                          if (index === x)
                                            i.weight = (
                                              i.weight * 2.20462
                                            ).toFixed(2);
                                          return i;
                                        }),
                                    });
                                  }
                                }}
                              />
                            </div>
                            {myErrors
                              ? myErrors[index]?.weight && (
                                  <div className="errorData">
                                    {myErrors[index]?.weight}
                                  </div>
                                )
                              : ""}
                          </div>
                          <div>
                            <div>
                              <label>Length + Girth</label>
                            </div>
                            <div className="variantName">
                              <input
                                type="number"
                                name={`product_variants.[${index}].length_girth`}
                                value={i["length_girth"]}
                                placeholder="Length Girth"
                                disabled
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <label>Dimensional Weight</label>
                            </div>
                            <div className="variantName">
                              <input
                                type="number"
                                name={`product_variants.[${index}].dimensional_weigth`}
                                value={i["dimensional_weigth"]}
                                placeholder="Dimensional Weight"
                                disabled
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <label>Units Per Case</label>
                            </div>
                            <div className="variantName">
                              <input
                                type="number"
                                name={`product_variants.[${index}].units_per_case`}
                                value={i["units_per_case"]}
                                placeholder="Units Per Case"
                                disabled={isViewMode}
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                onChange={(e) => {
                                  setFieldValue({
                                    ...values,
                                    product_variants:
                                      values.product_variants.map((i, x) => {
                                        if (index === x)
                                          i.units_per_case = e.target.value;
                                        return i;
                                      }),
                                  }).then(() =>
                                    validateField(
                                      `product_variants.[${index}].units_per_case`
                                    )
                                  );
                                }}
                              />
                            </div>
                            {myErrors
                              ? myErrors[index]?.units_per_case && (
                                  <div className="errorData">
                                    {myErrors[index]?.units_per_case}
                                  </div>
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <button onClick={handleAddVariant}>Add</button>
              )}
            </div>

            {!isViewMode ? (
              <div className="form_btn d-flex mt-3 text-center justify-content-center flex-wrap gap-2">
                <button type="submit" className="btn w-100 p-2 btnSubmit ">
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => {
                    navigate(`/${routesConstants.PRODUCTS}`);
                    dispatch(setProductDetailSuccess(null));
                  }}
                  className="btn w-100 p-2 btnCancel"
                >
                  Back
                </button>
              </div>
            ) : null}
          </form>
        </Card>
      </div>

      {isExitModel && (
        <ExitConfirmationModal
          onClickExit={() => navigate(`/${routesConstants.PRODUCTS}`)}
          show={isExitModel}
          onHide={() => setIsExitModel(false)}
          title=""
        />
      )}
    </>
  );
};

export default AddEditProduct;
