export const routesConstants = {
  PRODUCTS: "products",
  ADD_PRODUCT: "products/create",
  EDIT_PRODUCT: "products/edit/:product_id",
  VIEW_PRODUCT: "products/view/:product_id",
  FORGOT_PASSWORD: "forgot-password",
  SET_PASSWORD: "set-password/:token",
  add_edit_product: "add-edit-product",
  AUTH: "auth",
  LOGIN: "login",
  REGISTER: "register",
  SELLING_TEMPLATES: "selling-templates",
  ADD_SELLING_TEMPLATES: "selling-templates/create",
  EDIT_SELLING_TEMPLATES: "selling-templates/edit/:template_id",
  VIEW_SELLING_TEMPLATES: "selling-templates/view/:template_id",
  SELLING_PLANS: "selling-plans/:product_id",
};
