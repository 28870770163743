import {
  DELETE_PRODUCT_SELLING_PLAN,
  DELETE_SELLING_TEMPLATE,
  GENERATE_PRODUCT_SELLING_PLANS,
  GET_LANDED_COSTS_SOURCE_TO_DESTINATIONS,
  GET_PRODUCT_SELLING_PLAN,
  GET_SELLING_TEMPLATE_DETAIL,
  LIST_ALL_LANDING_COSTS,
  LIST_ALL_OVERHEAD_COST_GROUPS,
  LIST_ALL_SELLING_TEMPLATES,
  LIST_ALL_WEBSITES,
} from "constants/url";
import { toast } from "react-toastify";
import { axiosMilliardAdmin } from "services/api";
import {
  SET_SELLING_TEMPLATES,
  SET_SELLING_TEMPLATES_SUCCESS,
  SET_TEMPLATE_PAGE,
  SET_TEMPLATE_SIZE_PER_PAGE,
  SET_TEMPLATE_TOTAL_COUNT,
  SET_LANDING_COSTS,
  SET_LANDING_COSTS_SUCCESS,
  SET_OVERHEAD_COST_GROUP,
  SET_OVERHEAD_COST_GROUP_SUCCESS,
  SET_WEBSITES,
  SET_WEBSITES_SUCCESS,
  SET_SOURCE_DEST,
  SET_SOURCE_DEST_SUCCESS,
  SET_TEMPLATE_DETAIL,
  SET_TEMPLATE_DETAIL_SUCCESS,
  SET_SELLING_PLANS,
  SET_SELLING_PLANS_SUCCESS,
  LIST_SELLING_PLANS,
  LIST_SELLING_PLANS_SUCCESS,
} from "./actionTypes";

export const fetchSellingTemplates = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(setSellingTemplate(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_SELLING_TEMPLATES,
        payload
      );
      if (res?.data?.data) {
        dispatch(setSellingTemplateSuccess(res.data.data));
        dispatch(setTemplateTotalCount(res.data.data.length));
        cb(res.data.data);
      }
      dispatch(setSellingTemplate(false));
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
      dispatch(setSellingTemplate(false));
    }
  };
};

export const fetchLandingCosts = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLandingCosts(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_LANDING_COSTS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setLandingCostsSuccess(res.data.data));
      }
      dispatch(setLandingCosts(false));
    } catch (err) {
      dispatch(setLandingCosts(false));
    }
  };
};

export const fetchOverheadCostGroups = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setOverheadCostGroup(true));
      const res = await axiosMilliardAdmin.post(
        LIST_ALL_OVERHEAD_COST_GROUPS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setOverheadCostGroupSuccess(res.data.data));
      }
      dispatch(setOverheadCostGroup(false));
    } catch (err) {
      dispatch(setOverheadCostGroup(false));
    }
  };
};

export const fetchWebsites = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setWebsites(true));
      const res = await axiosMilliardAdmin.post(LIST_ALL_WEBSITES, payload);
      if (res?.data?.data) {
        dispatch(setWebsitesSuccess(res.data.data));
      }
      dispatch(setWebsites(false));
    } catch (err) {
      dispatch(setWebsites(false));
    }
  };
};

export const fetchCountries = () => {
  return async (dispatch) => {
    try {
      dispatch(setSourceDest(true));
      const res = await axiosMilliardAdmin.get(
        GET_LANDED_COSTS_SOURCE_TO_DESTINATIONS
      );
      if (res?.data?.data) {
        dispatch(setSourceDestSuccess(res.data.data));
      }
      dispatch(setSourceDest(false));
    } catch (err) {
      dispatch(setSourceDest(false));
    }
  };
};

export const deleteTemplate = (payload, cb) => {
  return async () => {
    try {
      const res = await axiosMilliardAdmin.post(
        DELETE_SELLING_TEMPLATE,
        payload
      );
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
    }
  };
};

export const fetchTemplateDetails = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setTemplateDetail(true));
      const res = await axiosMilliardAdmin.post(
        GET_SELLING_TEMPLATE_DETAIL,
        payload
      );
      if (res?.data?.data) {
        dispatch(setTemplateDetailSuccess(res.data.data));
      }
      dispatch(setTemplateDetail(false));
    } catch (err) {
      dispatch(setTemplateDetail(false));
    }
  };
};

export const generateSellingPlan = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch(setSellingPlan(true));
      const res = await axiosMilliardAdmin.post(
        GENERATE_PRODUCT_SELLING_PLANS,
        payload
      );
      if (res?.data?.data) {
        dispatch(setSellingPlanSuccess(res.data.data));
        cb(res?.data.data);
      }
      dispatch(setSellingPlan(false));
    } catch (err) {
      dispatch(setSellingPlan(false));
    }
  };
};

export const fetchSellingPlans = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(listSellingPlans(true));
      const res = await axiosMilliardAdmin.post(
        GET_PRODUCT_SELLING_PLAN,
        payload
      );
      if (res?.data?.data) {
        dispatch(listSellingPlansSuccess(res.data.data));
        cb(res.data.data);
      }
      dispatch(listSellingPlans(false));
    } catch (err) {
      dispatch(listSellingPlans(false));
    }
  };
};

export const deleteSellingPlan = (payload, cb) => {
  return async () => {
    try {
      const res = await axiosMilliardAdmin.post(
        DELETE_PRODUCT_SELLING_PLAN,
        payload
      );
      if (res) {
        cb(res);
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.Message || err.message);
    }
  };
};

export const setSellingTemplate = (data) => ({
  type: SET_SELLING_TEMPLATES,
  payload: data,
});

export const setSellingTemplateSuccess = (data) => ({
  type: SET_SELLING_TEMPLATES_SUCCESS,
  payload: data,
});

export const setLandingCosts = (data) => ({
  type: SET_LANDING_COSTS,
  payload: data,
});

export const setLandingCostsSuccess = (data) => ({
  type: SET_LANDING_COSTS_SUCCESS,
  payload: data,
});

export const setOverheadCostGroup = (data) => ({
  type: SET_OVERHEAD_COST_GROUP,
  payload: data,
});

export const setOverheadCostGroupSuccess = (data) => ({
  type: SET_OVERHEAD_COST_GROUP_SUCCESS,
  payload: data,
});

export const setWebsites = (data) => ({
  type: SET_WEBSITES,
  payload: data,
});

export const setWebsitesSuccess = (data) => ({
  type: SET_WEBSITES_SUCCESS,
  payload: data,
});

export const setSourceDest = (data) => ({
  type: SET_SOURCE_DEST,
  payload: data,
});

export const setSourceDestSuccess = (data) => ({
  type: SET_SOURCE_DEST_SUCCESS,
  payload: data,
});

export const setTemplatePage = (data) => ({
  type: SET_TEMPLATE_PAGE,
  payload: data,
});

export const setTemplateSizePerPage = (data) => ({
  type: SET_TEMPLATE_SIZE_PER_PAGE,
  payload: data,
});

export const setTemplateTotalCount = (data) => ({
  type: SET_TEMPLATE_TOTAL_COUNT,
  payload: data,
});

export const setTemplateDetail = (data) => ({
  type: SET_TEMPLATE_DETAIL,
  payload: data,
});

export const setTemplateDetailSuccess = (data) => ({
  type: SET_TEMPLATE_DETAIL_SUCCESS,
  payload: data,
});

export const setSellingPlan = (data) => ({
  type: SET_SELLING_PLANS,
  payload: data,
});

export const setSellingPlanSuccess = (data) => ({
  type: SET_SELLING_PLANS_SUCCESS,
  payload: data,
});

export const listSellingPlans = (data) => ({
  type: LIST_SELLING_PLANS,
  payload: data,
});

export const listSellingPlansSuccess = (data) => ({
  type: LIST_SELLING_PLANS_SUCCESS,
  payload: data,
});
