import React from "react";

import SellingTemplateTable from "../components/SellingTemplateTable";

const SellingTemplates = () => {
  return (
    <div>
      <SellingTemplateTable />
    </div>
  );
};

export default SellingTemplates;
