export const LOGIN = "/login";
export const USER_PATTEN = "/v1";
export const USER = "/list-user";
export const ADD_USER = "/register";
export const MODAL_CONFIGURATION = "/configurations";
export const FORGOT_PASSWORD = "forgotPassword";
export const SET_PASSWORD = "setPassword";
export const VERIFY_SET_PASSWORD_LINK = "verifySetPasswordLink";

export const ADD_UPDATE_PRODUCT = "addUpdateProduct";
export const LIST_PRODUCTS = "listProduct";
export const GET_PRODUCT_DETAILS = "getProductDetails";
export const AMAZON_PRODUCT_PRICE = "amazoneProductPrice";
export const FEDEX_PRODUCT_PRICE = "FedExProductPrice";
export const DELETE_PRODUCT = "deleteProduct";

export const LIST_ALL_SELLING_TEMPLATES = "listAllSellingTemplates";
export const LIST_ALL_LANDING_COSTS = "listAllLandingCosts";
export const LIST_ALL_OVERHEAD_COST_GROUPS = "listAllOverheadCostGroups";
export const LIST_ALL_WEBSITES = "listAllWebsites";
export const GET_OVERHEAD_COST_GROUP_DETAIL = "getOverheadCostGroupDetail";
export const GET_LANDED_COSTS_SOURCE_TO_DESTINATIONS =
  "getLandedCostsSourceToDestinations";
export const GET_ALL_COUNTRIES = "getAllCountries";
export const ADD_UPDATE_SELLING_TEMPLATE = "addUpdateSellingTemplate";
export const DELETE_SELLING_TEMPLATE = "deleteSellingTemplate";
export const GET_SELLING_TEMPLATE_DETAIL = "getSellingTemplateDetails";

export const GENERATE_PRODUCT_SELLING_PLANS = "generateProductSellingPlan";
export const GET_PRODUCT_SELLING_PLAN = "getProductSellingPlan";
export const DELETE_PRODUCT_SELLING_PLAN = "deleteProductSellingPlan";
