import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { routesConstants } from "../routes/routesConstants";
import templateIcon from "assets/icons/templates.png";

const Sidebar = () => {
  const location = useLocation();
  const isPathActive = (path) => {
    return location.pathname?.replace("/", "") === path;
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, []);

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li
            className={
              !isPathActive(`${routesConstants.PRODUCTS}`) &&
              !location.pathname?.startsWith("/product") &&
              !location.pathname?.startsWith("/selling-plans")
                ? "nav-item active"
                : "nav-item bgSide"
            }
          >
            <Link className="nav-link" to={`${routesConstants?.PRODUCTS}`}>
              <svg
                version="1.1"
                width="512"
                height="512"
                x="0"
                y="0"
                style={{
                  height: "26px",
                  width: "26px",
                  marginRight: "20px",
                }}
                viewBox="0 0 512 512"
                className=""
              >
                <g>
                  <path
                    d="m391 250-120 40 120 40 120-40zM1 290l120 40 120-40-120-40zM260.743.77a15.005 15.005 0 0 0-9.487 0L136 39.189l120 40 120-40zM136 512l105-35V321.623l-105 35zM1 466.189a14.998 14.998 0 0 0 10.257 14.23L106 512V356.623l-105-35zM271 477l105 35V356.623l-105-35zM406 512l94.743-31.581A15 15 0 0 0 511 466.189V321.623l-105 35zM136 229l105 35V105.812L136 70.811zM376 229V70.811l-105 35.001V264z"
                    fill="#ffffff"
                    data-original="#000000"
                    opacity="1"
                    className=""
                  ></path>
                </g>
              </svg>
              <span className="menu-title">Products</span>
            </Link>
          </li>
          <li
            className={
              !isPathActive(`${routesConstants.SELLING_TEMPLATES}`) &&
              !location.pathname?.startsWith("/selling-templates")
                ? "nav-item active"
                : "nav-item bgSide"
            }
          >
            <Link
              className="nav-link"
              to={`${routesConstants.SELLING_TEMPLATES}`}
            >
              <img
                src={templateIcon}
                alt="template"
                height="30px"
                className="me-3"
              />
              <span className="menu-title">Selling Templates</span>
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
