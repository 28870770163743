import {
  SET_AUTH,
  SET_VERIFY_LINK,
  SET_VERIFY_LINK_SUCCESS,
} from "./actionTypes";

const initialState = {
  isAuth: localStorage.getItem("access_token") || false,
  userLink: null,
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        isAuth: payload,
      };
    case SET_VERIFY_LINK:
      return {
        ...state,
        loading: payload,
      };
    case SET_VERIFY_LINK_SUCCESS:
      return {
        ...state,
        userLink: payload,
      };
    default:
      return state;
  }
};
export default authReducer;
