import { channels } from "constants";

import React, { useEffect, useMemo } from "react";
import { Card } from "react-bootstrap";
import Select from "react-select";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { groupUnits } from "constants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCountries,
  fetchLandingCosts,
  fetchOverheadCostGroups,
  fetchTemplateDetails,
  fetchWebsites,
} from "../store/actions";
import { unitEnum } from "constants";
import { axiosMilliardAdmin } from "services/api";
import {
  ADD_UPDATE_SELLING_TEMPLATE,
  GET_ALL_COUNTRIES,
  GET_OVERHEAD_COST_GROUP_DETAIL,
  LIST_ALL_LANDING_COSTS,
} from "constants/url";
import { toast } from "react-toastify";
import { routesConstants } from "routes/routesConstants";
import * as Yup from "yup"; // Import Yup for validation
import ExitConfirmationModal from "components/common/ExitConfirmationModal";

const AddEditSellingTemplate = () => {
  const { template_id } = useParams();

  const query = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pType, setPType] = useState();
  const [landedCostData, setLandedCostData] = useState();
  const [overheadData, setOverheadData] = useState();
  const [landedCosts, setLandedCost] = useState([]);
  const [source, setSource] = useState();
  const [countryId, setCountryId] = useState();
  const [allCountries, setAllCountries] = useState();
  const [productTypes, setProductTypes] = useState([]);
  const [isExitModel, setIsExitModel] = useState(false);

  const { overheadCostGroups, websites, countries, templateDetail } =
    useSelector((state) => state.sellingTemplateReducer);

  const overCostGroups = overheadCostGroups?.map((i) => ({
    ...i,
    label: i.group_title,
    value: i.overhead_group_id,
  }));

  const allWebsites = websites?.map((i) => ({
    ...i,
    label: i.website_name,
    value: i.website_id,
  }));

  const sourceDestCountries = countries?.map((i, index) => ({
    ...i,
    label: i.source_to_destination,
    value: index,
  }));

  const isViewMode = useMemo(() => {
    return query?.pathname.split("/").includes("view") ? true : false;
  }, [query]);

  const initialValues = {
    selling_template_id: 0,
    template_name: "",
    landed_cost_id: "",
    overhead_group_id: "",
    website_product_id: "",
    website_id: "",
    pallet_length: "",
    pallet_width: "",
    pallet_height: "",
    shipping_preference: 1,
    destination_zip: "",
    source_zip: "",
  };

  const validationSchema = Yup.object().shape({
    template_name: Yup.string().required("Please enter template name."),
    landed_cost_id: Yup.string().required(
      "Please select source to destination."
    ),
    overhead_group_id: Yup.string().required(
      "Please select overhead cost group."
    ),
    website_product_id: Yup.string().required("Please select website."),
    pallet_length: Yup.string().required("Required."),
    pallet_width: Yup.string().required("Required."),
    pallet_height: Yup.string().required("Required."),
    shipping_preference: Yup.number().required("Required."),
    destination_zip: Yup.string().test(
      "dest-required",
      "Please enter destination zip.",
      function (value) {
        const { shipping_preference } = this.parent;
        if (shipping_preference === 2) {
          return Yup.string().required().isValidSync(value);
        }
        return true;
      }
    ),
    source_zip: Yup.string().test(
      "source-required",
      "Please enter source zip.",
      function (value) {
        const { shipping_preference } = this.parent;
        if (shipping_preference === 2) {
          return Yup.string().required().isValidSync(value);
        }
        return true;
      }
    ),
  });

  useEffect(() => {
    dispatch(
      fetchLandingCosts({
        search: "",
      })
    );
    dispatch(
      fetchOverheadCostGroups({
        search: "",
      })
    );
    dispatch(
      fetchWebsites({
        search: "",
      })
    );
    dispatch(fetchCountries());
    handleAllCountries();
    if (template_id) {
      dispatch(
        fetchTemplateDetails({
          selling_template_id: template_id,
        })
      );
    }
  }, [dispatch, template_id]);

  const handleOverheadDetails = async (id) => {
    try {
      const res = await axiosMilliardAdmin.post(
        GET_OVERHEAD_COST_GROUP_DETAIL,
        {
          overhead_group_id: id,
        }
      );
      if (res) {
        setOverheadData(res.data.data);
      }
    } catch (err) {}
  };

  const handleLandedCost = async (data) => {
    try {
      const res = await axiosMilliardAdmin.post(LIST_ALL_LANDING_COSTS, {
        search: "",
        is_from_admin: 0,
        source_country: data.source_country,
        destination_country: data.destination_country,
      });

      if (res && res?.data?.data?.length > 0) {
        const landedCostGroup = template_id
          ? res.data.data?.find(
              (i) =>
                +i?.landed_cost_id ===
                +templateDetail?.landed_cost.landed_cost_id
            )
          : res?.data?.data[0];
        setFieldValue("landed_cost_id", landedCostGroup?.landed_cost_id).then(
          () =>
            setTimeout(() => {
              validateField("landed_cost_id");
            }, 0)
        );

        setLandedCost(
          res.data.data?.map((i) => ({
            ...i,
            label: i.landed_cost_name,
            value: i.landed_cost_id,
          }))
        );

        setLandedCostData({
          ...landedCostGroup,
          label: landedCostGroup.landed_cost_name,
          value: landedCostGroup?.landed_cost_id,
        });
      }
    } catch (err) {
      // Handle the error
    }
  };

  const handleAllCountries = async () => {
    try {
      const res = await axiosMilliardAdmin.get(GET_ALL_COUNTRIES);
      if (res) {
        setAllCountries(
          res.data.data?.map((i) => ({
            ...i,
            label: i.name,
            value: i.country_id,
          }))
        );
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleWebsites = (e) => {
    setFieldValue("website_id", e.value);
    setFieldValue(
      "website_product_id",
      e.website_products
        ?.map((i) => ({
          ...i,
          label: i.product_category,
          value: i.website_product_id,
        }))
        ?.find((i) => +i.is_default === 1)?.website_product_id
    ).then(() => validateField("website_product_id"));
    setProductTypes(
      e.website_products?.map((i) => ({
        ...i,
        label: i.product_category,
        value: i.website_product_id,
      }))
    );
    setPType(
      e.website_products
        ?.map((i) => ({
          ...i,
          label: i.product_category,
          value: i.website_product_id,
        }))
        ?.find((i) => +i.is_default === 1)
    );
  };

  const onSubmit = async (values) => {
    try {
      const payload = {
        selling_template_id: values?.selling_template_id,
        template_name: values?.template_name,
        landed_cost_id: values?.landed_cost_id,
        overhead_group_id: values?.overhead_group_id,
        website_product_id: values?.website_product_id,
        pallet_length: values?.pallet_length,
        pallet_width: values?.pallet_width,
        pallet_height: values?.pallet_height,
        shipping_preference: values?.shipping_preference,
        destination_zip: values?.destination_zip ? values?.destination_zip : 0,
        source_zip: values?.source_zip ? values?.source_zip : 0,
      };
      const res = await axiosMilliardAdmin.post(
        ADD_UPDATE_SELLING_TEMPLATE,
        payload
      );
      if (res) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate(`/${routesConstants.SELLING_TEMPLATES}`);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const {
    setFieldValue,
    errors,
    handleSubmit,
    validateField,
    values,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (templateDetail && template_id && allWebsites) {
      setProductTypes([
        {
          label: templateDetail?.website_product?.product_category,
          value: templateDetail?.website_product?.website_product_id,
        },
      ]);
      setPType(
        allWebsites
          .find(
            (i) =>
              i.website_id ===
              +templateDetail?.website_product.website.website_id
          )
          ?.website_products?.map((i) => ({
            ...i,
            label: i.product_category,
            value: i.website_product_id,
          }))
          ?.find(
            (i) =>
              i.website_product_id ===
              +templateDetail?.website_product.website_product_id
          )
      );
      resetForm({
        values: {
          selling_template_id: template_id,
          template_name: templateDetail?.template_name,
          landed_cost_id: templateDetail?.landed_cost.landed_cost_id,
          overhead_group_id:
            templateDetail?.overhead_cost_group.overhead_group_id,
          website_product_id:
            templateDetail?.website_product.website.website_id,
          pallet_length: templateDetail?.pallet_length,
          pallet_width: templateDetail?.pallet_width,
          pallet_height: templateDetail?.pallet_height,
          shipping_preference: templateDetail?.shipping_preference,
          destination_zip: templateDetail?.destination_zip,
          source_zip: templateDetail?.source_zip,
          website_id: templateDetail?.website_product?.website?.website_id,
        },
      });
      setCountryId(templateDetail?.website_product?.website?.country_id);
      setSource(
        sourceDestCountries?.find(
          (i) =>
            i.source_country === templateDetail?.landed_cost?.source_country &&
            i.destination_country ===
              templateDetail?.landed_cost?.destination_country
        )
      );
      handleLandedCost(
        sourceDestCountries?.find(
          (i) =>
            i.source_country === templateDetail?.landed_cost?.source_country &&
            i.destination_country ===
              templateDetail?.landed_cost?.destination_country
        )
      );
      handleOverheadDetails(
        templateDetail?.overhead_cost_group.overhead_group_id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDetail, dispatch, template_id, resetForm, countries]);

  return (
    <div className="selling col-12">
      <form onSubmit={handleSubmit}>
        <Card className="p-4 mt-2 selling_product_card w-100">
          <div className="top_title align-items-center mt-0 pb-3 mb-4 border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <i
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => {
                    if (isViewMode) {
                      navigate(`/${routesConstants.SELLING_TEMPLATES}`);
                    } else {
                      setIsExitModel(true);
                    }
                  }}
                ></i>
                <h4>
                  {template_id ? (isViewMode ? "View" : "Edit") : "Add"} Selling
                  Template
                </h4>
              </div>
              {!isViewMode && (
                <button
                  className="addbutton btn p-3 text-black shadow-sm fw-bold"
                  type="submit"
                >
                  {template_id ? "Save" : "Create"}
                </button>
              )}
            </div>
          </div>
          <div>
            <div>
              <div className="row mt-0 fData p_25 align-items-baseline">
                <div className="col-md-3 col-12 mt-0">
                  <label className="w-100 mt-2">Template Name:</label>
                </div>
                <div className="col-md-3 col-12 mt-0">
                  <input
                    type="text"
                    className="form-control w-100"
                    placeholder="Template Name"
                    name="template_name"
                    onChange={(e) => {
                      setFieldValue("template_name", e.target.value).then(() =>
                        validateField("template_name")
                      );
                    }}
                    value={values?.template_name}
                    disabled={isViewMode}
                  />
                  {errors?.template_name && (
                    <div className="errorData">{errors?.template_name}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6">
                  <div className="row g-2 mb-3 mt-3">
                    <div className="landingCostDiv col">
                      <div className="row g-3 lCost">
                        <div className="col">
                          <h3>
                            <b>Landed Costs</b>
                          </h3>
                        </div>
                      </div>
                      <div className="p_15">
                        <div
                          className={`row fData g-3 pt-3 ${
                            source?.label ? "pb-3" : ""
                          }  landing_Cost_Div_Heading align-items-center`}
                        >
                          <div className="col-md-6 col-12 mt-0">
                            <label className="pt-2">
                              Source to Destination:
                            </label>
                          </div>
                          <div className="col-md-6 col-12 mt-0">
                            <Select
                              options={sourceDestCountries}
                              onChange={(e) => {
                                handleLandedCost(e);
                                setSource(e);
                              }}
                              name={"landing_cost.title"}
                              value={sourceDestCountries?.filter(
                                (i) => i.value === source?.value
                              )}
                              isDisabled={isViewMode}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  border: "1px solid #e8e8e8 !important",
                                  width: "100% !important",
                                  padding: "2px 0 !important",
                                  borderRadius: "5px !important",
                                }),
                                control: (base) => ({
                                  ...base,
                                  border: "none !important",
                                  outline: "none !important",
                                  boxShadow: "none !important",
                                  minHeight: "35px !important",
                                }),
                                input: (base) => ({
                                  ...base,
                                  height: "30px !important",
                                  border: "none !important",
                                }),
                              }}
                            />
                            {errors?.landed_cost_id && (
                              <div className="errorData">
                                {errors?.landed_cost_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {landedCosts?.length > 0 && (
                          <div className="fData">
                            <div className="row g-3 align-items-center">
                              <div className="col-6">
                                <label className="col-form-label">
                                  Landed Cost Group:
                                </label>
                              </div>
                              <div className="col-md-6 col-12 mb-3">
                                <Select
                                  options={landedCosts}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "landed_cost_id",
                                      e.value
                                    ).then(() =>
                                      validateField("landed_cost_id")
                                    );
                                    setLandedCostData(e);
                                  }}
                                  name={"landed_cost_id"}
                                  value={landedCosts.filter(
                                    (i) =>
                                      i.value === landedCostData?.landed_cost_id
                                  )}
                                  isDisabled={isViewMode}
                                  styles={{
                                    container: (base) => ({
                                      ...base,
                                      border: "1px solid #e8e8e8 !important",
                                      width: "100% !important",
                                      padding: "2px 0 !important",
                                      borderRadius: "5px !important",
                                    }),
                                    control: (base) => ({
                                      ...base,
                                      border: "none !important",
                                      outline: "none !important",
                                      boxShadow: "none !important",
                                      minHeight: "35px !important",
                                    }),
                                    input: (base) => ({
                                      ...base,
                                      height: "30px !important",
                                      border: "none !important",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            {landedCostData && (
                              <>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6 mt-2">
                                    <label className="col-form-label">
                                      Sea Freight:
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`$${landedCostData?.sea_freight}`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6">
                                    <label className="col-form-label">
                                      Duties:
                                      <br />
                                      (Applied on Factory Cost)
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`${landedCostData?.duties}%`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6">
                                    <label className="col-form-label">
                                      Tarif:
                                      <br />
                                      (Applied on Factory Cost)
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`${landedCostData?.tarif}%`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6">
                                    <label className="col-form-label">
                                      Drayage:
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`$${landedCostData?.drayage}`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6">
                                    <label className="col-form-label">
                                      Unloading:
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`$${landedCostData?.unloading}`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mb-3 mt-3">
                    <div className="landingCostDiv col">
                      <div className="row g-3 lCost">
                        <div className="col">
                          <h3>
                            <b>Selling Channel</b>
                          </h3>
                        </div>
                      </div>
                      <div className="row g-3 pt-3 p_15">
                        <div className="fData">
                          <>
                            {" "}
                            <div className="row g-3 align-items-center mb-3">
                              <div className="col-6">
                                <label className="col-form-label">
                                  Source Country:
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="col-md-12 col-12 mt-0 d-flex">
                                  <Select
                                    options={allCountries}
                                    onChange={(e) => {
                                      setCountryId(e.value);
                                      dispatch(
                                        fetchWebsites({
                                          search: "",
                                          country_id: e.value,
                                          is_from_admin: 0,
                                        })
                                      );
                                    }}
                                    styles={{
                                      container: (base) => ({
                                        ...base,
                                        border: "1px solid #e8e8e8 !important",
                                        width: "100% !important",
                                        padding: "2px 0 !important",
                                        borderRadius: "5px !important",
                                      }),
                                      control: (base) => ({
                                        ...base,
                                        border: "none !important",
                                        outline: "none !important",
                                        boxShadow: "none !important",
                                        minHeight: "42px !important",
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        height: "30px !important",
                                        border: "none !important",
                                      }),
                                    }}
                                    isDisabled={isViewMode}
                                    value={allCountries?.filter(
                                      (i) => i.country_id === countryId
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row g-3 align-items-center mb-2">
                              <div className="col-6">
                                <label className="col-form-label">
                                  Fulfillment Channel:
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="col-md-12 col-12 mt-0 d-flex">
                                  <Select
                                    options={channels}
                                    name="shipping_preference"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "shipping_preference",
                                        e.value
                                      ).then(() =>
                                        validateField("shipping_preference")
                                      );
                                    }}
                                    styles={{
                                      container: (base) => ({
                                        ...base,
                                        border: "1px solid #e8e8e8 !important",
                                        width: "100% !important",
                                        padding: "2px 0 !important",
                                        borderRadius: "5px !important",
                                      }),
                                      control: (base) => ({
                                        ...base,
                                        border: "none !important",
                                        outline: "none !important",
                                        boxShadow: "none !important",
                                        minHeight: "42px !important",
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        height: "30px !important",
                                        border: "none !important",
                                      }),
                                    }}
                                    isDisabled={isViewMode}
                                    value={channels.filter(
                                      (i) =>
                                        i.value === +values.shipping_preference
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row g-3 align-items-center mb-2">
                              <div className="col-6">
                                <label className="col-form-label">
                                  Website:
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="col-md-12 col-12 mt-0 d-flex">
                                  <Select
                                    options={allWebsites}
                                    onChange={(e) => handleWebsites(e)}
                                    styles={{
                                      container: (base) => ({
                                        ...base,
                                        border: "1px solid #e8e8e8 !important",
                                        width: "100% !important",
                                        padding: "2px 0 !important",
                                        borderRadius: "5px !important",
                                      }),
                                      control: (base) => ({
                                        ...base,
                                        border: "none !important",
                                        outline: "none !important",
                                        boxShadow: "none !important",
                                        minHeight: "42px !important",
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        height: "30px !important",
                                        border: "none !important",
                                      }),
                                    }}
                                    value={allWebsites?.filter(
                                      (i) => i.value === values?.website_id
                                    )}
                                    isDisabled={isViewMode}
                                  />
                                </div>
                                {errors?.website_product_id && (
                                  <div className="errorData">
                                    {errors?.website_product_id}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row g-3 align-items-center mb-2">
                              <div className="col-6">
                                <label className="col-form-label">
                                  Product Type:
                                </label>
                              </div>
                              <div className="col-6">
                                <div className="col-md-12 col-12 mt-0 d-flex">
                                  <Select
                                    options={productTypes}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "website_product_id",
                                        e.value
                                      ).then(() =>
                                        validateField("website_product_id")
                                      );
                                      setPType(e);
                                    }}
                                    value={pType}
                                    styles={{
                                      container: (base) => ({
                                        ...base,
                                        border: "1px solid #e8e8e8 !important",
                                        width: "100% !important",
                                        padding: "2px 0 !important",
                                        borderRadius: "5px !important",
                                      }),
                                      control: (base) => ({
                                        ...base,
                                        border: "none !important",
                                        outline: "none !important",
                                        boxShadow: "none !important",
                                        minHeight: "42px !important",
                                      }),
                                      input: (base) => ({
                                        ...base,
                                        height: "30px !important",
                                        border: "none !important",
                                      }),
                                    }}
                                    isDisabled={isViewMode}
                                  />
                                </div>
                              </div>
                            </div>
                            {pType && (
                              <>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6 mt-2">
                                    <label className="col-form-label">
                                      Tier 1 (Applied on SP)
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`${
                                            pType[0]?.tier_1_commission ||
                                            pType?.tier_1_commission
                                          }%`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6 mt-2">
                                    <label className="col-form-label">
                                      Tier 2 (Applied on SP)
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`${
                                            pType[0]?.tier_2_commission ||
                                            pType?.tier_2_commission
                                          }%`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-3 align-items-center">
                                  <div className="col-md-6 mt-2">
                                    <label className="col-form-label">
                                      Tire2 applied Above
                                    </label>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="input-group mb-3">
                                      <div className="form-floating">
                                        <input
                                          type="text"
                                          id=""
                                          className="form-control"
                                          aria-describedby="passwordHelpInline"
                                          value={`${
                                            pType[0]?.tier_condition ||
                                            pType?.tier_condition
                                          } Units`}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  {" "}
                  <div className="row g-2 mb-3 mt-3">
                    <div className="landingCostDiv overheadCost overhead col">
                      <div className="row g-3 lCost">
                        <div className="col">
                          <h3>
                            <b>Overhead Costs</b>
                          </h3>
                        </div>
                      </div>

                      <div className="row g-3 p_15 pb-0 mb_10">
                        <div className="d-flex flex-wrap">
                          <div className="col-md-6 col-12">
                            <label className="w-100 mt-3">
                              Overhead Cost Group:
                            </label>
                          </div>
                          <div className="col-md-6 col-12">
                            <Select
                              options={overCostGroups}
                              name="overhead_group_id"
                              value={overCostGroups?.filter(
                                (i) =>
                                  i.value === overheadData?.overhead_group_id
                              )}
                              onChange={(e) => {
                                setFieldValue(
                                  "overhead_group_id",
                                  e.value
                                ).then(() =>
                                  validateField("overhead_group_id")
                                );
                                handleOverheadDetails(e.value);
                              }}
                              isDisabled={isViewMode}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  border: "1px solid #e8e8e8 !important",
                                  width: "100% !important",
                                  padding: "2px 0 !important",
                                  borderRadius: "5px !important",
                                }),
                                control: (base) => ({
                                  ...base,
                                  border: "none !important",
                                  outline: "none !important",
                                  boxShadow: "none !important",
                                  minHeight: "42px !important",
                                }),
                                input: (base) => ({
                                  ...base,
                                  height: "30px !important",
                                  border: "none !important",
                                }),
                              }}
                            />
                            {errors?.overhead_group_id && (
                              <div className="errorData">
                                {errors?.overhead_group_id}
                              </div>
                            )}
                          </div>
                        </div>
                        {overheadData ? (
                          <>
                            {overheadData?.overhead_cost_groups_details?.map(
                              (i, index) => (
                                <>
                                  <div className="mt-3" key={index}>
                                    <span className="fontBig">
                                      {i.cost_group_title}
                                    </span>
                                    <div className="containDiv">
                                      {i.cost_types_masters?.map((x, y) => (
                                        <div
                                          className="d-flex flex-wrap mb-2"
                                          key={y}
                                        >
                                          <div className="col-md-3 col-12">
                                            <label className="w-100">
                                              {x.cost_type}:
                                            </label>
                                          </div>
                                          <div className="col-md-9 col-12 d-flex gap-3 align-items-start">
                                            <input
                                              type="text"
                                              className="form-control w-100 m-0"
                                              value={`$${x.cost}`}
                                              disabled
                                            />
                                            <Select
                                              options={groupUnits}
                                              placeholder="Per"
                                              name="overhead_cost.return_unit_unit"
                                              value={groupUnits?.filter(
                                                (i) =>
                                                  i.label ===
                                                  unitEnum[x.cost_unit]
                                              )}
                                              // disabled={isViewMode}
                                              isDisabled
                                              styles={{
                                                container: (base) => ({
                                                  ...base,
                                                  border:
                                                    "1px solid #e8e8e8 !important",
                                                  width: "40% !important",
                                                  padding: "2px 0 !important",
                                                  borderRadius:
                                                    "5px !important",
                                                }),
                                                control: (base) => ({
                                                  ...base,
                                                  border: "none !important",
                                                  outline: "none !important",
                                                  boxShadow: "none !important",
                                                  minHeight: "42px !important",
                                                }),
                                                input: (base) => ({
                                                  ...base,
                                                  height: "30px !important",
                                                  border: "none !important",
                                                }),
                                                indicatorsContainer: (
                                                  base
                                                ) => ({
                                                  ...base,
                                                  display: "none",
                                                }),
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 mb-3 mt-3">
                    <div className="landingCostDiv overheadCost col">
                      <div className="row g-3 lCost">
                        <div className="col">
                          <h3>
                            <b>Shipping Costs</b>
                          </h3>
                        </div>
                      </div>
                      <div className="p_15">
                        <div className="row g-3 mt-3">
                          <div className="col-md-4 col-12 m-0">
                            <label className="w-100 m-0">
                              Shipping Preference:
                            </label>
                          </div>
                          <div className="col-md-8 col-12 d-flex flex-wrap mt-0">
                            {!isViewMode && (
                              <>
                                <div className="form-check w-50">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="shipping_preference"
                                    id="FBA"
                                    value={1}
                                    checked={
                                      +values.shipping_preference === 1
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        "shipping_preference",
                                        e.target.value
                                      ).then(() =>
                                        validateField("shipping_preference")
                                      );
                                    }}
                                    disabled={isViewMode}
                                  />
                                  <label
                                    className="form-check-label m-0 w-75"
                                    htmlFor="FBA"
                                  >
                                    FBA
                                  </label>
                                </div>
                                <div className="form-check w-50">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="shipping_preference"
                                    id="FedEx"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "shipping_preference",
                                        e.target.value
                                      ).then(() =>
                                        validateField("shipping_preference")
                                      );
                                    }}
                                    checked={
                                      +values.shipping_preference === 2
                                        ? true
                                        : false
                                    }
                                    value={2}
                                    disabled={isViewMode}
                                  />
                                  <label
                                    className="form-check-label m-0 w-75"
                                    htmlFor="FedEx"
                                  >
                                    FedEx
                                  </label>
                                </div>
                              </>
                            )}
                            {isViewMode && (
                              <label>
                                {+values.shipping_preference === 1 ? (
                                  <span>FBA</span>
                                ) : +values.shipping_preference === 2 ? (
                                  <span>FedEx</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            )}
                          </div>
                        </div>
                        {+values.shipping_preference === 2 && (
                          <>
                            <div className="row g-3 mt-0">
                              <div className="col-md-3 col-12">
                                <label className="w-100">Source Zip:</label>
                              </div>
                              <div className="col-md-8 col-12">
                                <input
                                  type="number"
                                  className="form-control w_75"
                                  placeholder="Source Zip"
                                  name="source_zip"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "source_zip",
                                      e.target.value
                                    ).then(() => validateField("source_zip"));
                                  }}
                                  value={values?.source_zip}
                                  disabled={isViewMode}
                                />
                                {errors?.source_zip && (
                                  <div className="errorData">
                                    {errors?.source_zip}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row g-3 mt-0">
                              <div className="col-md-3 col-12">
                                <label className="w-100">
                                  Destination Zip:
                                </label>
                              </div>
                              <div className="col-md-8 col-12">
                                <input
                                  type="number"
                                  className="form-control w_75"
                                  placeholder="Destination Zip"
                                  name="destination_zip"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "destination_zip",
                                      e.target.value
                                    ).then(() =>
                                      validateField("destination_zip")
                                    );
                                  }}
                                  value={values?.destination_zip}
                                  disabled={isViewMode}
                                />
                                {errors?.destination_zip && (
                                  <div className="errorData">
                                    {errors?.destination_zip}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row g-3 mt-0">
                          <div className="col-md-3 col-12">
                            <label className="w-100">Pallet Size: (Inch)</label>
                          </div>
                          <div className="col-md-3 col-12 ">
                            {/* <label>L</label> */}
                            <div className="d-flex">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="L"
                                name="pallet_length"
                                onChange={(e) => {
                                  setFieldValue(
                                    "pallet_length",
                                    e.target.value
                                  ).then(() => validateField("pallet_length"));
                                }}
                                value={values?.pallet_length}
                                disabled={isViewMode}
                              />
                              <span className="f30 ps-2">*</span>
                            </div>
                            {errors?.pallet_length && (
                              <span className="errorData">
                                {errors?.pallet_length}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3 col-12 ">
                            {/* <label>W</label> */}
                            <div className="d-flex">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="W"
                                name="pallet_width"
                                onChange={(e) => {
                                  setFieldValue(
                                    "pallet_width",
                                    e.target.value
                                  ).then(() => validateField("pallet_width"));
                                }}
                                value={values?.pallet_width}
                                disabled={isViewMode}
                              />
                              <span className="f30 ps-2">*</span>
                            </div>
                            {errors?.pallet_width && (
                              <span className="errorData">
                                {errors?.pallet_width}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3 col-12 ">
                            {/* <label>H</label> */}
                            <div className="d-flex">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="H"
                                name="pallet_height"
                                onChange={(e) => {
                                  setFieldValue(
                                    "pallet_height",
                                    e.target.value
                                  ).then(() => validateField("pallet_height"));
                                }}
                                value={values?.pallet_height}
                                disabled={isViewMode}
                              />
                              <span className="ps-2"></span>
                            </div>
                            {errors?.pallet_height && (
                              <span className="errorData">
                                {errors?.pallet_height}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3"></div>
            </div>
            {/* </form> */}
          </div>
        </Card>
      </form>
      {isExitModel && !isViewMode && (
        <ExitConfirmationModal
          onClickExit={() => navigate(`/${routesConstants.SELLING_TEMPLATES}`)}
          show={isExitModel}
          onHide={() => setIsExitModel(false)}
          title=""
        />
      )}
    </div>
  );
};

export default AddEditSellingTemplate;
