import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchSellingPlans, generateSellingPlan } from "../store/actions";
import { useParams } from "react-router-dom";

const initialValues = {
  factory_cost: 0,
  product_variant_id: 0,
  selling_plan_id: 0,
  selling_price: "",
  selling_template_id: 0,
  selling_plan_name: "",
};
const ManageSellingPrice = ({ handleClose, isOpen, planDetails }) => {
  const dispatch = useDispatch();
  const { product_id } = useParams();

  const { loading } = useSelector((state) => state.sellingTemplateReducer);

  const validationSchema = Yup.object().shape({
    selling_price: Yup.string().required("Please enter selling price."),
  });

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      selling_price: parseFloat(values?.selling_price),
      factory_cost: parseFloat(values?.factory_cost),
    };
    dispatch(
      generateSellingPlan(payload, (res) => {
        if (res) {
          handleClose();
          dispatch(
            fetchSellingPlans({
              product_id: product_id,
              is_from_admin: 0,
            })
          );
          resetForm({
            values: initialValues,
          });
        }
      })
    );
  };

  const {
    setFieldValue,
    errors,
    handleSubmit,
    validateField,
    values,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnMount: false,
    validationSchema,
    onSubmit,
    validateOnChange: false,
  });

  useEffect(() => {
    if (planDetails) {
      resetForm({
        values: {
          selling_plan_name: planDetails?.selling_plan_name,
          selling_plan_id: planDetails?.selling_plan_id,
          selling_template_id: planDetails?.selling_template_id,
          product_variant_id: planDetails?.product_variant_id,
          selling_price: parseFloat(planDetails?.summry?.selling_price || 0),
          factory_cost: parseFloat(planDetails?.landed_cost?.factory_cost || 0),
        },
      });
    }
  }, [dispatch, resetForm, planDetails]);

  return (
    <Modal show={isOpen} className="sell-price fade delete_popup tempForm">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <Modal.Header className="lex-column d-inline-block">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              onClick={() => {
                handleClose();
                resetForm({
                  values: initialValues,
                });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <h4 className="modal-title w-100">Update Selling Price(SP)</h4>
              <div className="tempData row">
                <div className="mt-3">
                  <input
                    type="number"
                    className="form-control half_to_full"
                    placeholder="Selling Price"
                    name="selling_price"
                    onChange={(e) => {
                      setFieldValue("selling_price", e.target.value).then(() =>
                        validateField("selling_price")
                      );
                    }}
                    value={values?.selling_price}
                  />
                  {errors?.selling_price && (
                    <div className="errorData position-absolute">
                      {errors?.selling_price}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button
                  className="addbutton btn p-3 text-black shadow-sm fw-bold"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              </div>
            </form>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ManageSellingPrice;
