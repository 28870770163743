import ConfirmationModal from "components/common/ConfirmationModal";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";

import { useNavigate } from "react-router-dom";
import { routesConstants } from "routes/routesConstants";

import {
  deleteTemplate,
  fetchSellingTemplates,
  setTemplatePage,
  setTemplateSizePerPage,
} from "../store/actions";
import Pagination from "components/common/Pagination";
import TableSelect from "components/common/TableSelect";
import { sizePerPageList } from "constants";
import { getHiddenRowKeys } from "utils/getHiddenRowKeys";
import { Oval } from "react-loader-spinner";

const SellingTemplateTable = () => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [templateData, setTemplateData] = useState();
  const [templateId, setTemplateId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageNo, sizePerPage, sellingTemplateList, loading } = useSelector(
    (state) => state.sellingTemplateReducer
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      dataField: "",
      text: "Action",
      headerAlign: "center",
      headerStyle: {
        width: "20%", // Set the width of the header cell
      },
      formatter: (cell, row) => {
        return (
          <span className="table_action_btn d-flex fs-5 gap-2 justify-content-center">
            <i
              className="fa-regular fa-eye table_eye pointer"
              onClick={() => {
                navigate(
                  `/${routesConstants.SELLING_TEMPLATES}/view/${row.selling_template_id}`
                );
              }}
              title="View"
            ></i>
            <i
              className="fa-solid fa-pen-to-square table_edit pointer"
              onClick={() => {
                navigate(
                  `/${routesConstants.SELLING_TEMPLATES}/edit/${row.selling_template_id}`
                );
              }}
              title="Edit"
            ></i>
            <i
              className="fa-solid fa-trash  table_delete pointer"
              onClick={() => {
                setIsOpenDelete(true);
                setTemplateId(row.selling_template_id);
              }}
              title="Delete"
            ></i>
          </span>
        );
      },
    },
    {
      dataField: "template_name",
      text: "Template Name",
      headerAlign: "left",
    },
    {
      dataField: "landed_cost.landed_cost_name",
      headerAlign: "left",
      text: "Landed Cost",
    },
    {
      dataField: "website_product.website.website_name",
      headerAlign: "left",
      text: "Website",
    },
    {
      dataField: "website_product.product_category",
      headerAlign: "left",
      text: "Product Type",
    },
    {
      dataField: "shipping_preference",
      headerAlign: "left",
      text: "Fulfillment Channel",
    },
    {
      dataField: "overhead_cost_group.group_title",
      headerAlign: "left",
      text: "Overhead Cost Group",
    },
  ]);

  const handleDeleteSellingTemplate = () => {
    dispatch(
      deleteTemplate(
        {
          selling_template_id: templateId,
        },
        (res) => {
          if (res) {
            setIsOpenDelete(false);
            dispatch(
              fetchSellingTemplates(
                {
                  search: "",
                },
                (res) => {
                  if (res) {
                    setTemplateData(res);
                  }
                }
              )
            );
          }
        }
      )
    );
  };

  const handleAddSellingTemplates = () => {
    navigate(`/${routesConstants.ADD_SELLING_TEMPLATES}`);
  };

  const handleClose = () => {
    setIsOpenDelete(false);
  };

  useEffect(() => {
    dispatch(
      fetchSellingTemplates(
        {
          search: "",
        },
        (res) => {
          if (res) {
            setTemplateData(res);
          }
        }
      )
    );
  }, [dispatch]);

  const handlePageChange = (value) => {
    dispatch(setTemplatePage(value));
  };

  const handleSizePerPageChange = ({ value }) => {
    dispatch(setTemplateSizePerPage(value));
    dispatch(setTemplatePage(1));
  };

  const { hiddenRows } = getHiddenRowKeys({
    page: pageNo,
    sizePerPage,
    list: sellingTemplateList,
    keyField: "selling_template_id",
  });

  const handleFilter = (search) => {
    if (search === "") setTemplateData(sellingTemplateList);
    else {
      let array = [];
      array = sellingTemplateList?.filter(
        (f) =>
          f.template_name?.toLowerCase()?.search(search.toLowerCase()) !== -1
      );
      setTemplateData(array);
    }
  };
  return (
    <div>
      <Card className="p-4 mt-5 ">
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>Selling Templates</h4>
          <button className="btnAddAdmin" onClick={handleAddSellingTemplates}>
            Add
          </button>
        </div>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3">
          <div>
            <TableSelect
              options={sizePerPageList}
              value={{ label: sizePerPage, value: sizePerPage }}
              onChange={handleSizePerPageChange}
              className="me-1 mt-2"
            />
          </div>
          <input
            type="search"
            className="searchInput"
            placeholder="Search"
            onChange={(e) => handleFilter(e.target.value)}
          />
        </div>

        {loading ? (
          <Oval
            height={40}
            width={40}
            color="#464243"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#464243"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        ) : (
          <div className="mb-2 d-flex flex-column ">
            <div className="flex-grow-1">
              <div className="table-responsive main-table">
                <BootstrapTable
                  noDataIndication={() => {
                    <span className="text-center margin_left_45">
                      No Data Found
                    </span>;
                  }}
                  hiddenRows={hiddenRows}
                  responsive
                  bordered={false}
                  striped={false}
                  keyField={"selling_template_id"}
                  data={templateData?.length > 0 ? templateData : []}
                  columns={columns}
                  hover
                  classes={`table table-hover`}
                />
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div></div>
          {templateData?.length > 0 && (
            <div>
              <Pagination
                onChange={handlePageChange}
                page={+pageNo}
                sizePerPage={sizePerPage}
                totalRecords={templateData?.length}
              />
            </div>
          )}
        </div>
      </Card>
      <ConfirmationModal
        isOpen={isOpenDelete}
        handleClose={handleClose}
        cb={handleDeleteSellingTemplate}
        btnText={"Delete"}
        title={isOpenDelete}
        subTitle={"Are you sure want to delete this Selling Template?"}
      />
    </div>
  );
};

export default SellingTemplateTable;
