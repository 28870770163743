import React from "react";
import { Modal } from "react-bootstrap";

const ExitConfirmationModal = ({
  title = "",
  show,
  onClickExit,
  onHide,
  name,
}) => {
  return (
    <Modal show={show} className="fade delete_popup">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <Modal.Header className="lex-column d-inline-block">
            <Modal.Title className="icon-box">
              <i className="fa-sharp fa-solid fa-circle-exclamation"></i>
            </Modal.Title>
            <h4 className="modal-title w-100">
              {"Are you sure you want to leave?"}
            </h4>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              onClick={onHide}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </Modal.Header>

          <Modal.Footer className="justify-content-center mt-2">
            <button type="button" onClick={onHide}>
              {"No"}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={onClickExit}
            >
              {"Yes " + title}
            </button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};

export default ExitConfirmationModal;
