import React from "react";
import { Card } from "react-bootstrap";
import { useState } from "react";
import TemplateModal from "./TemplateModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSellingPlan,
  fetchSellingPlans,
  generateSellingPlan,
} from "../store/actions";
import { useParams } from "react-router-dom";
import ConfirmationModal from "components/common/ConfirmationModal";
import ManageSellingPrice from "./ManageSellingPrice";

const ManageSellingPlans = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [planId, setPlanId] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [estimatedSales, setEstimatedSale] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isSellingPriceOpen, setIsSellingPriceOpen] = useState(false);

  const dispatch = useDispatch();
  const { product_id } = useParams();
  const { sellingPlansList } = useSelector(
    (state) => state.sellingTemplateReducer
  );

  const handleAddPlan = () => {
    setIsOpen(false);
    setIsEdit(false);
  };

  useEffect(() => {
    dispatch(
      fetchSellingPlans(
        {
          product_id: product_id,
          is_from_admin: 0,
        },
        (res) => {
          if (res) {
            if (res.length === 0) {
              setIsOpen(true);
            }
            setEstimatedSale(
              res.map((item, index) => ({
                id: index,
                sales: 1,
              }))
            );
          }
        }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, product_id]);

  const handleDeleteSellingPlan = () => {
    dispatch(
      deleteSellingPlan(
        {
          selling_plan_id: planId,
        },
        (res) => {
          if (res) {
            setIsOpenDelete(false);
            dispatch(
              fetchSellingPlans({
                product_id: product_id,
                is_from_admin: 0,
              })
            );
          }
        }
      )
    );
  };

  const handleRefreshPlan = (data) => {
    dispatch(
      generateSellingPlan(
        {
          selling_plan_id: data?.selling_plan_id,
          selling_plan_name: data?.selling_plan_name,
          selling_template_id: data?.selling_template_id,
          product_variant_id: data?.product_variant_id,
          selling_price: data?.selling_price,
          factory_cost: data?.factory_cost,
        },
        (res) => {
          if (res) {
            dispatch(
              fetchSellingPlans({
                product_id: product_id,
                is_from_admin: 0,
              })
            );
          }
        }
      )
    );
  };

  const handleClose = () => {
    setIsOpenDelete(false);
  };

  return (
    <div>
      <Card className={`p-4 mt-5 manage_product_card`}>
        <div className="top_title d-flex justify-content-between align-items-center mt-0 pb-3 mb-4 border-bottom">
          <h4>{"Manage Selling Plans"}</h4>
          <button
            className="addbutton btn p-3 text-black shadow-sm fw-bold"
            type="button"
            onClick={() => setIsOpen(true)}
          >
            Add
          </button>
        </div>
        <form>
          <div>
            <div className="mb-3 mt-3 row w-100">
              {sellingPlansList?.map((i, index) => (
                <div className="mb-4 col-xl-4 col-md-6 col-12">
                  <div className="reportDiv">
                    <div className="tempDiv">
                      <span>{i.selling_plan_name}</span>
                      <div>
                        <i
                          className="fa-solid fa-arrows-rotate pointer me-3"
                          onClick={() => {
                            handleRefreshPlan(i);
                          }}
                        ></i>
                        <i
                          className="fa-solid fa-pen-to-square table_edit me-3 pointer"
                          onClick={() => {
                            setIsOpen(true);
                            setIsEdit(true);
                            setPlanDetails(i);
                          }}
                        ></i>
                        <i
                          className="fa-solid fa-trash  table_delete pointer"
                          onClick={() => {
                            setPlanId(i?.selling_plan_id);
                            setIsOpenDelete(true);
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`product_variant${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#product${i?.selling_plan_id}`}
                              aria-expanded="true"
                              aria-controls="product"
                            >
                              Product Details
                            </button>
                          </h2>
                          <div
                            id={`product${i?.selling_plan_id}`}
                            className={`accordion-collapse collapse show`}
                            aria-labelledby={`product_variant${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <label>Variant </label>
                                <label>{i.product_details?.variant}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Longest Side (Inch) </label>
                                <label>{i.product_details?.longest_side}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Median Side (Inch) </label>
                                <label>{i.product_details?.median_side}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Shortest Side (Inch)</label>
                                <label>
                                  {i.product_details?.shortest_side}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Weight (LBS)</label>
                                <label>{i.product_details?.weight}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Length & Girth </label>
                                <label>{i.product_details?.length_girth}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Dimensional Weight </label>
                                <label>
                                  {i.product_details?.dimensional_weigth}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Units Per Case </label>
                                <label>
                                  {i.product_details?.units_per_case}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`landing_cost${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#lCost${i?.selling_plan_id}`}
                              aria-expanded="false"
                              aria-controls="lCost"
                            >
                              Landed Cost ($
                              {i.landed_cost?.landed_cost_total})
                            </button>
                          </h2>
                          <div
                            id={`lCost${i?.selling_plan_id}`}
                            className={`accordion-collapse collapse show`}
                            aria-labelledby={`landing_cost${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <label>Source </label>
                                <label>{i.landed_cost?.source}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Destination </label>
                                <label>{i.landed_cost?.destination}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Landed Cost Group </label>
                                <label className="text-right">
                                  {i.landed_cost?.landed_cost_group}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Factory Cost(FC) </label>
                                <label>${i.landed_cost?.factory_cost}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Sea Freight (Per 67 CBM)</label>
                                <label className="nowrap">
                                  ${i.landed_cost?.sea_freight}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>
                                  Duties ({i.landed_cost?.duties_percentage}% on
                                  FC)
                                </label>
                                <label className="nowrap">
                                  ${i.landed_cost?.duties}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>
                                  Tarif ({i.landed_cost?.tarif_percentage}% on
                                  FC)
                                </label>
                                <label className="nowrap">
                                  ${i.landed_cost?.tarif}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Drayage (Per 67 CBM)</label>
                                <label className="nowrap">
                                  ${i.landed_cost?.drayage}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Unloading (Per 67 CBM)</label>
                                <label className="nowrap">
                                  ${i.landed_cost?.unloading}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`channel_cost${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#channel${i?.selling_plan_id}`}
                              aria-expanded="false"
                              aria-controls="channel"
                            >
                              Channel Cost ($
                              {i.channel_cost?.channel_cost_total})
                            </button>
                          </h2>
                          <div
                            id={`channel${i?.selling_plan_id}`}
                            className={`accordion-collapse collapse show`}
                            aria-labelledby={`channel_cost${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <label>Website </label>
                                <label>{i.channel_cost?.website}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Product Type </label>
                                <label>{i.channel_cost?.product_type}</label>
                              </div>
                              <div className="d-flex justify-content-between">
                                <label>Channel Cost </label>
                                <label>
                                  ${i.channel_cost?.channel_cost_total}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`overhead_cost${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#overhead${i?.selling_plan_id}`}
                              aria-expanded="false"
                              aria-controls="overhead"
                            >
                              Overhead Cost ($
                              {i.overhead_cost?.overhead_cost_total})
                            </button>
                          </h2>
                          <div
                            id={`overhead${i?.selling_plan_id}`}
                            className="accordion-collapse collapse show"
                            aria-labelledby={`overhead_cost${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <label>Overhead Cost Group </label>
                                <label className="text-right">
                                  {i.overhead_cost?.overhead_cost_group}
                                </label>
                              </div>
                              {i.overhead_cost?.overhead_cost_details?.map(
                                (x) => (
                                  <div className="d-flex justify-content-between">
                                    <label>{x?.cost_type} </label>
                                    <label className="nowrap">
                                      ${x?.final_cost}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`shipping_cost${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#ship${i?.selling_plan_id}`}
                              aria-expanded="false"
                              aria-controls="ship"
                            >
                              Shipping Cost ($
                              {i.shipping_cost?.shipping_cost_total})
                            </button>
                          </h2>
                          <div
                            id={`ship${i?.selling_plan_id}`}
                            className={`accordion-collapse collapse show`}
                            aria-labelledby={`shipping_cost${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-between">
                                <label>Shipping Preference </label>
                                <label>
                                  {i.shipping_cost?.shipping_preference === 1
                                    ? "FBA"
                                    : i.shipping_cost?.shipping_preference === 2
                                    ? "FedEx"
                                    : ""}{" "}
                                </label>
                              </div>
                              {i.shipping_cost?.shipping_preference === 2 && (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <label>Source Zip </label>
                                    <label>{i.shipping_cost?.source_zip}</label>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <label>Destination Zip </label>
                                    <label>
                                      {i.shipping_cost?.destination_zip}
                                    </label>
                                  </div>
                                </>
                              )}
                              <div className="d-flex justify-content-between">
                                <label>Fulfillment Fees </label>
                                <label className="nowrap">
                                  ${i.shipping_cost?.fulfillment_fees}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id={`summary_cost${i?.selling_plan_id}`}
                          >
                            <button
                              className={`accordion-button`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#summary${i?.selling_plan_id}`}
                              aria-expanded="false"
                              aria-controls="summary"
                            >
                              Summary
                            </button>
                          </h2>
                          <div
                            id={`summary${i?.selling_plan_id}`}
                            className={`accordion-collapse collapse show`}
                            aria-labelledby={`summary_cost${i?.selling_plan_id}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="row">
                                <div className="col-4 text-center">
                                  <div>
                                    <label>Selling Price </label>
                                  </div>
                                  <div className="d-flex ms-2">
                                    <div className="me-2">
                                      <label className="nowrap fw">
                                        ${i.summry?.selling_price}
                                      </label>
                                    </div>
                                    <div
                                      onClick={() => {
                                        setPlanDetails(i);
                                        setIsSellingPriceOpen(true);
                                      }}
                                    >
                                      <i className="fa-solid fa-pen-to-square table_edit  pointer"></i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-4 text-center">
                                  <div>
                                    <label>Cost Per Unit </label>
                                  </div>
                                  <div>
                                    <b>
                                      <label className="nowrap fw">
                                        ${i.summry?.cost_per_unit}
                                      </label>
                                    </b>
                                  </div>
                                </div>
                                <div className="col-4 text-center">
                                  <div>
                                    <label>Estimated Sales </label>
                                  </div>
                                  <div>
                                    <input
                                      min={0}
                                      type="number"
                                      className="w-100"
                                      onChange={(e) => {
                                        setEstimatedSale(
                                          estimatedSales?.map((i, x) => {
                                            if (index === x)
                                              i.sales = e.target.value;
                                            return i;
                                          })
                                        );
                                      }}
                                      value={estimatedSales[index]?.sales}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row pDiv">
                                <div className="col-5 profitDiv margin_20 text-center">
                                  <div>
                                    <label>Net Profit </label>
                                  </div>
                                  <div>
                                    <label className="nowrap fw">
                                      $
                                      {(
                                        i.summry?.net_profit *
                                        (estimatedSales[index]?.sales || 1)
                                      )?.toFixed(2)}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-5 profitDiv text-center">
                                  <div>
                                    <label>Net Margin </label>
                                  </div>
                                  <div>
                                    <label className="nowrap fw">
                                      {i.summry?.net_margin}%
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </Card>
      <TemplateModal
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          setIsEdit(false);
          setPlanDetails("");
        }}
        cb={handleAddPlan}
        title={isEdit ? "Edit Selling Plan" : "Create Selling Plan"}
        isEdit={isEdit}
        planDetails={planDetails}
        setPlanDetails={setPlanDetails}
        setEstimatedSale={setEstimatedSale}
      />
      <ConfirmationModal
        isOpen={isOpenDelete}
        handleClose={handleClose}
        cb={handleDeleteSellingPlan}
        btnText={"Delete"}
        title={isOpenDelete}
        subTitle={"Are you sure want to delete this Selling Plan?"}
      />
      <ManageSellingPrice
        isOpen={isSellingPriceOpen}
        handleClose={() => setIsSellingPriceOpen(false)}
        planDetails={planDetails}
      />
    </div>
  );
};

export default ManageSellingPlans;
