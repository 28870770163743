import { useEffect } from "react";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocalStorage from "./services/localStorage";
import { handleSetAuth } from "./store/actions";
import { useDispatch } from "react-redux";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let token = LocalStorage.get("access_token");
    if (!!token) {
      dispatch(handleSetAuth(true));
    }
  }, [dispatch]);
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
};
export default App;
