export const productUnits = [
  {
    label: "CM",
    value: 0,
  },
  {
    label: "Inch",
    value: 1,
  },
];

export const weightUnits = [
  {
    label: "KG",
    value: 0,
  },
  {
    label: "LBS",
    value: 1,
  },
];

export const channels = [
  {
    label: "FBA",
    value: 1,
  },
  {
    label: "FedEx",
    value: 2,
  },
];

export const productCategoryConst = [
  {
    label: "Default",
    value: 0,
  },
  {
    label: "Furniture",
    value: 1,
  },
  {
    label: "Electronics",
    value: 2,
  },
  {
    label: "Cloths",
    value: 3,
  },
];

export const unitEnum = {
  1: "Pallet",
  2: "Unit",
  3: "Case",
  4: "Cubic FT",
};

export const groupUnits = [
  {
    label: "Pallet",
    value: "Pallet",
  },
  {
    label: "Unit",
    value: "Unit",
  },
  {
    label: "Case",
    value: "Case",
  },
  {
    label: "Cubic FT",
    value: "Cubic FT",
  },
];

export const tempConst = [
  {
    label: "Template 1",
    value: "Template 1",
  },
  {
    label: "Template 2",
    value: "Template 2",
  },
];

export const varConst = [
  {
    label: "Variant 1",
    value: "Variant 1",
  },
  {
    label: "Variant 2",
    value: "Variant 2",
  },
];

export const sizePerPageList = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  {
    label: 15,
    value: 15,
  },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
