import { combineReducers } from "redux";

import Auth from "./Auth/reducer";
import productReducer from "modules/Products/store/reducer";
import sellingTemplateReducer from "modules/SellingTemplates/store/reducer";

const rootReducer = combineReducers({
  Auth,
  productReducer,
  sellingTemplateReducer,
});

export default rootReducer;
