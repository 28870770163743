import React from "react";
import SimpleDotLoader from "./SimpleDotLoader";

const PageLoader = () => {
  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="main-panel">
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div>
              {/* <img src={Icon} alt="Logo" style={{ height: "100px" }} /> */}
              <div className="mt-4 d-flex justify-content-center align-items-center">
                <SimpleDotLoader />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
