import { Outlet } from "react-router-dom";
import {
  Products,
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
} from "./routeImports";

import AddEditProduct from "modules/Products/components/addEditProduct";
// import ViewProduct from "../modules/ViewProduct";

import { routesConstants } from "./routesConstants";
import SellingTemplates from "modules/SellingTemplates/pages";
import AddEditSellingTemplate from "modules/SellingTemplates/components/addEditSellingTemplate";
import ManageSellingPlans from "modules/SellingTemplates/components/ManageSellingPlans";
import ResetPassword from "modules/Login/components/ResetPassword";
import CheckPasswordPagePermission from "components/common/CheckPasswordPagePermission";
const routesConfig = {
  private: [
    {
      path: routesConstants.PRODUCTS,
      Component: Outlet,
      children: [{ index: true, Component: Products }],
    },
    {
      path: routesConstants.ADD_PRODUCT,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: AddEditProduct,
        },
      ],
    },
    {
      path: routesConstants.EDIT_PRODUCT,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: AddEditProduct,
        },
      ],
    },
    {
      path: routesConstants.VIEW_PRODUCT,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: AddEditProduct,
        },
      ],
    },
    {
      path: routesConstants.SELLING_TEMPLATES,
      Component: Outlet,
      children: [
        {
          index: true,
          Component: SellingTemplates,
        },
      ],
    },
    {
      path: routesConstants.ADD_SELLING_TEMPLATES,
      Component: Outlet,
      children: [{ index: true, Component: AddEditSellingTemplate }],
    },
    {
      path: routesConstants.EDIT_SELLING_TEMPLATES,
      Component: Outlet,
      children: [{ index: true, Component: AddEditSellingTemplate }],
    },
    {
      path: routesConstants.VIEW_SELLING_TEMPLATES,
      Component: Outlet,
      children: [{ index: true, Component: AddEditSellingTemplate }],
    },
    {
      path: routesConstants.SELLING_PLANS,
      Component: Outlet,
      children: [{ index: true, Component: ManageSellingPlans }],
    },
  ],
  public: [
    {
      path: routesConstants.LOGIN,
      Component: LoginPage,
    },
    {
      path: routesConstants.REGISTER,
      Component: RegisterPage,
    },
    {
      path: routesConstants.SET_PASSWORD,
      Component: CheckPasswordPagePermission,
      children: [{ index: true, Component: ResetPassword }],
    },
    {
      path: routesConstants.FORGOT_PASSWORD,
      Component: ForgotPasswordPage,
    },
  ],
};

export default routesConfig;
