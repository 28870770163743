export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCTS_SUCCESS = "SET_PRODUCTS_SUCCESS";
export const SET_PRODUCTS_ERRORS = "SET_PRODUCTS_ERRORS";

export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_PRODUCT_DETAILS_SUCCESS = "SET_PRODUCT_DETAILS_SUCCESS";

export const SET_PAGE = "SET_PAGE";
export const SET_SIZE_PER_PAGE = "SET_SIZE_PER_PAGE";
export const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
export const SET_SORT_FIELD = "SET_SORT_FIELD";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
