import React, { useState } from "react";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import navicon from "../assets/images/nav-logo.png";
import { routesConstants } from "../routes/routesConstants";
import Cookies from "../services/cookies";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleSetAuth } from "../store/actions";
import SignOutConfirmationModal from "../components/common/SignOutConfirmationModal";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { axiosMilliardAdmin } from "services/api";

const Header = () => {
  const HOST = process.env.REACT_APP_API_URL;
  const AccessToken = localStorage.getItem("access_token");
  const dimDivisorValue = localStorage.getItem("dim_divisor");
  const [showModal, setShowModal] = useState(false);
  const [isSignOutModel, setIsSignOutModel] = useState(false);
  const FirtName = localStorage.getItem("first_name");
  const LastName = localStorage.getItem("last_name");
  const dispatch = useDispatch();

  const headers = {
    Authorization: `Bearer ${JSON.parse(AccessToken)}`,
  };

  const validationSchema = Yup.object().shape({
    dimDivisor: Yup.number()
      .required("Required.")
      .test(
        "greater-than-zero",
        "Please enter a value greater than 0",
        (value) => {
          return value > 0;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      dimDivisor: dimDivisorValue || 139,
    },
    validationSchema,
    onSubmit: (values) => {
      handleDimDivisorSubmit(values.dimDivisor);
    },
  });

  const handleLogout = async () => {
    try {
      const res = await axiosMilliardAdmin.put(
        "/logout",
        {
          access_token: JSON.parse(AccessToken),
        },
        { headers }
      );
      if (res) {
        Cookies.clear();
        localStorage.clear();
        dispatch(handleSetAuth(false));
        setIsSignOutModel(false);
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDimDivisorSubmit = (dimDivisor) => {
    axios
      .put(
        `${HOST}/api/updateProfile`,
        {
          dim_divisor: dimDivisor,
        },
        { headers }
      )
      .then(function (response) {
        const updatedDimDivisor = response.data.data.dim_divisor;
        localStorage.setItem("dim_divisor", updatedDimDivisor);

        setShowModal(false);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(function (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link
            className="navbar-brand brand-logo text-white fw-bold"
            to={routesConstants?.products}
          >
            <span>Milliard Profit Estimator</span>
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img src={navicon} alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center">
          <button
            className="navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown>
                <Dropdown.Toggle className="nav-link align-items-center justify-content-center">
                  <div className="nav-profile-img">
                    <i
                      className="fa-solid fa-user"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                  <div className="nav-profile-text">
                    <span>
                      {`${FirtName} ${LastName}`.replace(/['"]+/g, "")}
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item
                    onClick={() => setShowModal(true)}
                    style={{ color: "black" }}
                  >
                    Update Dim Divisor
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setIsSignOutModel(true);
                    }}
                    style={{ color: "black" }}
                  >
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-none align-self-center"
            type="button"
            onClick={() =>
              document
                .querySelector(".sidebar-offcanvas")
                .classList.toggle("active")
            }
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      {isSignOutModel && (
        <SignOutConfirmationModal
          onClickSignOut={handleLogout}
          show={isSignOutModel}
          onHide={() => setIsSignOutModel(false)}
          title=""
        />
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Dim Divisor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="dimDivisor">
              <Form.Label>Dim Divisor</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Dim Divisor"
                name="dimDivisor"
                value={formik.values.dimDivisor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                onWheel={(e) => e.target.blur()}
              />
              {formik.touched.dimDivisor && formik.errors.dimDivisor && (
                <div className="text-danger mt-2 mb-2 input_main_div float-right">
                  <span className="error_msg mt-1">
                    {formik.errors.dimDivisor}
                  </span>
                </div>
              )}
            </Form.Group>
            <Modal.Footer>
              <div className="form_btn_fees mt-4">
                <button className="btn w-100 p-2" type="submit">
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
